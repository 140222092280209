import React, { useEffect, useRef } from 'react'
import { init } from 'ityped'

const Intro = () => {
    const textRef = useRef()
    const [hasFinished, setHasFinished] = React.useState(false)

    function showContact(){
        setHasFinished(true)
    }

    useEffect(() => {
        init(textRef.current, {
            showCursor: true,
            backDelay: 1500,
            typeSpeed: 80,
            backSpeed: 50,
            disableBackTyping: true,
            onFinished: showContact,
            strings: ['Saša.', 'a web developer.', 'a dancer.', 'a proponent of cultural integrity.', 'a student of life.', 'humbled... Thank you for being here. Let\'s create something together <3']
        })
    }, [])

    return (
        <div className='intro'>
            <h1 className='hello'>Hello</h1>
            <p className='i-am'>I'm <span ref={textRef}></span></p>
            {hasFinished &&
            <a href= "mailto: saska.develop@gmail.com">Contact me</a>
            }
        </div>
    )
}

export default Intro