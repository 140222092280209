import './app.css'
import '../intro/intro.css'
import Intro from '../intro/Intro';

function App() {
  return (
    <div className='app'>
    <Intro/>
    </div>
  );
}

export default App;
